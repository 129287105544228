import "./src/styles/global.css";
import * as Sentry from "@sentry/gatsby";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://2fa31f0f8663b4a002592797170550f2@o4507329664057344.ingest.de.sentry.io/4508239878226000",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}
